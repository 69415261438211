<template>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                <i class="bx bx-send animated bounceInDown show-info"></i>
                New Complaint
                </h3>
            </div>
        </div>
    </div>
    <div class="flex-row">
        <div class="col-md-12">
            <div class="panel panel-cascade">
                <div class="info-box bg-primary text-white">
                    <div class="info-icon bg-warning-dark">
                        <i class="fa fa fa-user fa-2x"></i>
                    </div>
                    <div class="info-details">
                        <h4>Complaint Details</h4>
                    </div>
                </div>
                <div class="panel-body" style="height:550px">
                    <div class="form-horizontal cascde-forms">
                        <div class="form-group flex-row">
                            <label class="col-lg-4 col-md-3 control-label">Customer VSDIGI ID<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9">
                                <input class="form-control form-cascade-control input-small" v-model="customername" type="text" value="" @input="getUser()"/>
                                <p v-if="founduser!=null">{{ founduser.name}}</p>
                                <p v-if="usernotfound" style="color:red">Customer Not Found</p>
                            </div>
                        </div>
                        
                        <div class="form-group row">
                            <div class="col-lg-4 col-md-3">
                                <label for="subcategory" class="col-form-label control-label">Select Category</label>
                            </div>
                            <div class="col-lg-8 col-md-9">
                                <select class="form-control form-cascade-control input-small" v-model="parentid" ref="myInput">
                                    <option selected>----Select-----</option>
                                    <option v-for="item in parentcategory" v-bind:value="item.id" :key="item.id">{{item.name}}</option>
                                </select>
                                <span class="invalid-feedback" role="alert">
                                    <strong></strong>
                                </span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4 col-md-3">
                                <label for="subcategory" class="col-form-label control-label">Select Product</label>
                            </div>
                            <div class="col-lg-8 col-md-9">
                                <select @change="fetchbrands()" class="form-control form-cascade-control input-small" v-model="category_id">
                                <option selected>----Select-----</option>
                                <option v-for="item in subcategory" v-bind:value="item.id" :key="item.id">{{item.name}}</option>
                                </select>
                                <span class="invalid-feedback" role="alert">
                                    <strong></strong>
                                </span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class=" col-lg-4 col-md-3">
                                <label for="subcategory" class="col-form-label control-label">Company</label>
                            </div>
                            <div class="col-lg-8 col-md-9">
                                <select v-model="brandid" @change="fetchproducts()" class="form-control form-cascade-control input-small" >
                                    <option value="0">---Select---</option>
                                    <option v-for="filter in brands" :key="filter.id" :value="filter.id">{{filter.value}}</option>
                                </select>
                                <span class="invalid-feedback" role="alert">
                                    <strong></strong>
                                </span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4 col-md-3">
                                <label for="firstname" class="col-form-label control-label">Model Name<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                            </div>
                            <div class="col-lg-8 col-md-9">
                                <select v-model="productid" class="form-control form-cascade-control input-small" >
                                    <option v-for="filter in products" :key="filter.id" :value="filter.id">{{filter.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group flex-row">
                            <label class="col-lg-4 col-md-3 control-label">Complaint Description</label>
                            <div class="col-lg-8 col-md-9">
                                <textarea class="form-control form-cascade-control input-small" cols="20" v-model="remark" rows="2">
                                </textarea>
                            </div>
                        </div>
                        <div class="form-group flex-row">
                            <label class="col-lg-4 col-md-3 control-label">Date of Purchase<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9">
                                <input type="date" class="form-control form-cascade-control input-small" v-model="leaddate">
                                <!-- <vc-date-picker class="inline-block h-full" :model-config="modelConfig" >
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <div class="flex items-center">
                                            <button type="button"
                                                class="date-picker"
                                                @click="togglePopover()">
                                                <i class='bx bx-calendar'></i>
                                            </button>
                                            <input :value="inputValue" @click="togglePopover()"
                                                class="form-control form-cascade-control input-small"
                                                placeholder="DD/MM/YYYY" readonly />
                                        </div>
                                    </template>
                                </vc-date-picker> -->
                            </div>
                        </div>
                        <!-- <div class="form-group flex-row" v-if="loggedinuser?.role>=2">
                            <label class="col-lg-4 col-md-3 control-label">Followed By<span style="color:red">*</span></label>
                            <div class="col-lg-8 col-md-9">
                                <select v-model="followedid" class="form-control form-cascade-control input-small">
                                    <option v-for="filter in employees" :key="filter.id" :value="filter.id">{{filter.name}}</option>
                                </select>             
                                <font color="red"><span class="field-validation-valid" data-valmsg-for="FOLLOWEDBYID" data-valmsg-replace="true"></span></font>
                            </div>
                        </div>-->
                        <div style="display:none" class="form-group flex-row" v-if="loggedinuser?.role<2">
                            <label class="col-lg-4 col-md-3 control-label">Followed By<span style="color:red">*</span></label>
                            <div class="col-lg-8 col-md-9">
                                <select v-model="followedid" class="form-control form-cascade-control input-small">
                                    <option v-for="filter in employees.filter(property => property.id ==loggedinuser.id )" :key="filter.id" :value="filter.id" ><span >{{filter.name}}</span></option>
                                </select>             
                                <font color="red"><span class="field-validation-valid" data-valmsg-for="FOLLOWEDBYID" data-valmsg-replace="true"></span></font>
                            </div>
                        </div> 
                          <!--  <div class="form-group flex-row">
                            <label class="col-lg-4 col-md-3 control-label">Request<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                            <div class="col-lg-8 col-md-9">
                                <select class="form-control form-cascade-control input-small" v-model="requesttype">
                                    <option value="2">COMPLAINT</option>
                                </select>
                                <font color="red"><span class="field-validation-valid" data-valmsg-for="REQUEST" data-valmsg-replace="true"></span></font>
                            </div>
                        </div> -->
                </div>
            </div>
        </div>
        <!-- <div class="col-md-6">
            <div class="panel panel-cascade">
                <div class="info-box bg-primary text-white">
                    <div class="info-icon bg-warning-dark">
                        <i class="fa fa fa-user fa-2x"></i>
                    </div>
                    <div class="info-details">
                        <h4>Details</h4>
                    </div>
                </div>
                <div class="panel-body" style="height:300px">
                    <div class="form-horizontal cascde-forms">
                        
                        </div>
                        <div class="form-group flex-row">
                            <label class="col-lg-4 col-md-3 control-label">Lead By<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                            <div class="col-lg-8 col-md-9">
                                <select class="form-control form-cascade-control input-small" v-model="leadtype" name="WALKIN"><option value="0">SELECT</option>
                                    <option value="1">INCOMING CALL</option>
                                    <option value="2">OUTGOING CALL</option>
                                    <option value="3">NEWS PAPER ADD</option>
                                    <option value="4">SHOW ROOM WALKIN</option>
                                    <option value="5">HOME VISIT</option>
                                    <option value="6">REFFERRED BY OTHER</option>
                                    <option value="7">INTERNET</option>
                                    <option value="8">BY ADVISOR</option>
                                </select>
                            </div>
                        </div>
                        </div>
                    </div>
                </div> -->
            <!-- </div> -->
        </div>
        <div class=" col-md-12 text-center">
            <input type="submit" value="Submit" style="width:20%" class="btn bg-primary text-white btn-md" @click="submit()">
        </div>
        
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
// import ReferralSelector from '../../components/user/ReferralSelector.vue'
// import { required, maxLength,minLength } from 'vuelidate/lib/validators'
export default{
    components:{},
    data(){
        return{
            followedid:'',
            requesttype:2,
            remark:'',
            leadtype:0,
            id:0,
            leaddate:'',
            checkduplicate:false,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
            usernotfound:false,
            customername:'',
            founduser:[],
            brandid:0,
            products:[],
            parentid:0,
            category_id:0,
            brands:[],
            productid:0,
            
        }
    },
    validations:{
        
    },
    computed:{
        ...mapGetters(['employees','referraluser','loggedinuser','categories']),
        parentcategory(){
            if(this.categories!=null){
                return this.categories.filter(block=>block.parent_id==0)
            }
            return [];
        },
        subcategory(){
            if(this.categories!=null){
                return this.categories.filter(block=>block.parent_id==this.parentid)
            }
            return [];
        },
        /*brands(){
            let attribute = this.attributes.find(block=>block.id==2)
            return attribute
        },*/
        predefinedvalues(){
            if(this.brands!=null && this.brands.predefinedvalues!=null){
                return this.brands.predefinedvalues
            }
            return [];
        },
        getval(value){
            return value
        },

        filters(){
            return this.categories.filter(item => item.parent_id != 0 && item.parent_id != null)
        },
        selectedproduct(){
            if(this.products!=null){
                return  this.products.find(block=>block.id==this.productid)
            }
            return [];
        },
    },
    mounted(){
        this.refresh();
    },
    methods:{
        getUser(){
            let param={generatedid:this.customername}
            this.$http.post('api/user/getuserbygeneratedid',param)
            .then((response) => this.processGetUSerResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processGetUSerResponse(data){
            if(data!=null){
                this.founduser=data
                // this.usernotfound=false
            }else{
                // this.usernotfound=true
            }
        },
        
        mobileduplicate(){
            let param={mobileno:this.mobileno,requesttype:2}
            this.$http.post('api/leads/check/mobile',param) 
            .then((response) => this.processCheckResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processCheckResponse(data){
            if(data==true){
                this.checkduplicate=true
            }else{
                this.checkduplicate=false
            }
        },
        refresh(){
            this.$store.dispatch('fetchemployees')     
            this.$store.dispatch("fetchproducts")
            this.$store.dispatch('fetchcategories')
            this.$store.dispatch('fetchattributes')                                                             
        },
        initializedata(){
            this.followedid='',
            this.customername='',
            this.requesttype=2,
            this.remark='',
            this.leadtype=0,
            this.leaddate='',
            this.founduser=[]
        },
        submit(){
            this.$notify({text:'Submitted',type:'warn'})
            if(this.checkduplicate==false && !this.$v.$invalid){
                let param = {id:this.id,followedid:this.followedid,
                            customerid:this.founduser?.id??0,
                            requesttype:this.requesttype,
                            remark:this.remark,
                            isdispose:0,leaddate:this.leaddate,
                            productid:this.productid
                }
                this.$store.commit('assignloadingstatus',1)
                this.$http.post('api/leads/createupdate',param)
                .then((response) => this.processLeadResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
            }else{
                this.$notify({text:'Please Avoid the Error Mention in the Form',type:'error'})
            }
        },
        processLeadResponse(){
            this.initializedata();
            this.$notify({text:'Submitted Successfully',type:'success'})
            this.$store.commit('assignloadingstatus',0)
        },
        
        fetchbrands(){
            
            let param = {parentcategoryid:this.category_id,attributeid:2}
            window.axios.post('api/attribute/values/fetchbycategory',param)
    			.then(response=>this.processAvailableBrands(response.data))
    			.catch(error=>console.log(error));
        },
        processAvailableBrands(data){
            this.brands = data
        },
        fetchproducts(){
            
            let param = {parentcategoryid:this.category_id,attributeid:2,attributevalueids:this.brandid}
            window.axios.post('api/products/fetchbyfilters',param)
    			.then(response=>this.processProductsResponse(response.data))
    			.catch(error=>console.log(error));
        },
        processProductsResponse(data){
            this.products = data
        },
        
    }
}
</script>
<style>
.flex-row{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap;
}
</style>